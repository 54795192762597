/* eslint-disable */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { LanguageProvider } from 'react-i18n';
import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import 'balloon-css/balloon.min.css';
import { syncHistoryWithStore } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import './theme/globalStyles';
import configureStore from './configureStore';
import Routes from './routes';
import { getProp, prop } from './utilities/';
import ErrorBoundary from './containers/Errors/ErrorBoundary';
import {
  loadTranslations,
  resolveLangByHostname,
  resolveLangFromCookies,
} from './configureTrans';
import { FullscreenLoading } from 'eshop-defaults';
import { default as API } from './services/API';
import { theme } from './theme/theme';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ErrorBoundaryNew } from './containers/Errors/ErrorBoundaryNew';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import ReactGA from 'react-ga4';

declare global {
  interface Window {
    __INIT_DATA__: any;
    __REDUX_STATE__: any;
    __ANALYTICS_CONFIG_DATA__: any;
    grecaptcha: any;
    _hrq: any;
    handleRecaptcha?: any;
    dataLayer: any[];
    Packeta: any;
    GlsWidget: any;
  }
}

API.setBaseUrl(process.env.REACT_APP_API_BASE_URL || '');

const reduxState = getProp(window, '__REDUX_STATE__');
if (!reduxState || reduxState === '{{SSR_INITIAL_STATE}}') {
  window.__REDUX_STATE__ = {};
}
const store = configureStore(window.__REDUX_STATE__);
const historyObj = syncHistoryWithStore(browserHistory, store);

const loadTranslationsData = () => loadTranslations(location);
const lang = resolveLangFromCookies('');

if (!isSSR()) {
  // if (
  //   window &&
  //   window.__REDUX_STATE__ &&
  //   window.__REDUX_STATE__.general &&
  //   window.__ANALYTICS_CONFIG_DATA__
  // ) {
  //   console.log('INIT GA4');
  //   ReactGA.initialize(
  //     window.__ANALYTICS_CONFIG_DATA__[
  //       `Google_analyticsCode-${window.__REDUX_STATE__.general.lang || '-'}-0`
  //     ],
  //   );
  // }

  if (typeof window !== 'undefined') {
    if (window.__REDUX_STATE__) {
      API.setToken(prop(window.__REDUX_STATE__, 'auth.token', undefined));
    }

    Sentry.init({
      dsn:
        'https://31e384af28ed4fedb937f61ab116043e@o1222353.ingest.sentry.io/4504695035133952',
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  ReactDOM.hydrate(
    <ThemeProvider theme={theme}>
      <LanguageProvider
        translations={window.__INIT_DATA__.translations}
        loadCatalogues={loadTranslationsData}
        loadingContainer={<FullscreenLoading />}
      >
        <Provider store={store}>
          <Sentry.ErrorBoundary
            fallback={
              <ErrorBoundaryNew
                lang={resolveLangByHostname(prop(location, 'hostname'))}
              />
            }
            showDialog={false}
          >
            <Routes history={historyObj} lang={lang} />
          </Sentry.ErrorBoundary>
        </Provider>
      </LanguageProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );
}

export { store };
